.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 10vh;
  display: flex;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: fixed;
}

.App-link {
  color: #61dafb;
}

.App-body {
  height: 90vh;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px; 
}

.BigText {
  font-size: 24px;
}

.MediumText {
  font-size: 18px;
}

code {
  background-color: rgb(20, 20, 20);
  border-radius: 5px;
  padding: 2px 4px;
  color: #eee;
  text-shadow: rgb(0 0 0 / 30%) 0px 1px;
  font-family: Ubuntu Mono,source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.TextArea {
  padding: 10px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

